/**
 * Hair Form component.
 * Takes form data and validates it.
 * If successful, an api request is sent to './api/HairSubmission.php'.
 * A modal is shown on a successful submission.
 * The postcode checker checks for regional postcodes using the postcode validator package.
 * 
 * @method validateInput : (string) => boolean
 * @method validateEmail : (string) => boolean
 * @method validateCodeLength : (string) => boolean
 * @method validateRegionalPostcode : (string) => boolean
 * @method queryLIMS : async () => void
 * @method formatResults : (QueryInterface) => void
 * @method submitHandler : (React.FormEvent) => void
 * 
 * @returns HairForm : PureComponent 
 */

import "./HairForm.scss";
import { FormEvent, useRef, useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { BsArrowLeftSquareFill } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import Checkbox from "../inputs/Checkbox";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// endpoint
// Dev
// const endpoint = "http://localhost:80/activatemytest/api/controllers/HairSubmission.php";
// Staging
// const endpoint = "https://staging.activatemytest.com/api/controllers/HairSubmission.php";
// Live
const endpoint = "https://activatemytest.com/api/controllers/HairSubmission.php";

// const hairExportEndpoint = "http://localhost:80/activatemytest/api/cron/HairExport.php";

const HairForm = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Navigate the route to the thank you page after submission
    const navigate = useNavigate();

    if (postcodeValidatorExistsForCountry("GB") === false) {

        throw new Error("Warning, postcode validator doesn't work. Please check the package");
    }

    // Input refs
    const firstNameRef = useRef<HTMLInputElement>(null);
    const lastNameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const [orderNumberPrefix, setOrderNumberPrefix] = useState("");
    const prefixes = [
        "SC-",
        "SC-US-",
        "SC-CA-",
        "SC-IE-",
        "SC-NZ-",
        "SC-AU-",
        "TYI-",
        "TYI-CA-",
        "TYI-NZ-",
        "AT-IE-",
        "AT-",
        "AT-AU-",
        "TMFS-",
        "AMZ-",
        "TMP-UK-",
        "TMP-US-",
        "TMP-CA-",
        "TMP-IE-"

    ];
    const [prefixMessage, setPrefixMessage] = useState(""); // State to ho
    const secondEmailRef = useRef<HTMLInputElement>(null);
    const postcodeRef = useRef<HTMLInputElement>(null);
    const orderNumberRef = useRef<HTMLInputElement>(null);
    const reCaptchaRef = useRef<ReCAPTCHA>(null);
    const genderRef = useRef<HTMLSelectElement>(null);
    const activationCodeRef = useRef<HTMLInputElement>(null);
    const [consentChecked, setConsentChecked] = useState<boolean>(false);

    // Form validation states
    const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true);
    const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);
    const [isPostcodeValid, setIsPostcodeValid] = useState<boolean>(true);
    const [isOrderNumberValid, setIsOrderNumberValid] = useState<boolean>(true);
    const [isSecondEmailValid, setIsSecondEmailValid] = useState<boolean>(true);
    const [activationCodeErrorMsg, setActivationCodeErrorMsg] = useState<string>("");
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(new Date("2000-01-01"));
    const [orderNumberErrorMsg, setOrderNumberErrorMsg] = useState<string>("");
    const [isActivationCodeValid, setIsActivationCodeValid] = useState<boolean>(true);
    const [formSuccess, setFormSuccess] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [isReCaptchaValid, setIsReCaptchaValid] = useState<boolean>(false);
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [isPrefixValid, setIsPrefixValid] = useState(true);


    // Validate regional postcode
    const validateRegionalPostcode = (postcode: string) => {

        let postcodeIsValid = false;

        // Postcodes array, we validate these to make the validator "global"
        const postcodes = [
            "GB",
            "US",
            "AU",
            "CA",
            "DE",
            "NL",
            "PL",
            "FI",
            "FR",
            "DE",
            "CH",
            "CN",
            "SE",
            "IE",
            "LU",
            "NO",
            "BE",
            "AT",
            "PL",
            "SG",
            "KR",
            "NZ",
            "JP",
            "MY",
            "AR",
            "ZA",
            "TW",
            "TH"
        ];

        // Loop through the array of postcodes to match the postcode
        // If true, we return true at the end of this function, otherwise, it stays false
        // eslint-disable-next-line array-callback-return
        postcodes.map((code: string) => {

            if (postcodeValidator(postcode, code) === true) {
                postcodeIsValid = true;
            }
        });

        return postcodeIsValid;
    };

    // Toggle checkbox
    const handleCheckboxToggle = () => {

        setConsentChecked(!consentChecked);
    };


    // Validate our email address as being valid by checking it against regular expression
    const validateEmail = (email: string) => {
        return /^[^@]+@[^@]+\.[^@]+$/.test(email);
    };

    // Make sure the input is not empty
    const validateInput = (input: string) => {
        return input.length > 0;
    };

    const validateOrderNumber = (code: string) => {
        // Regular expression to check if the code contains only numbers and dashes
        const validOrderNumberPattern = /^[0-9-]+$/;

        if (!validOrderNumberPattern.test(code)) {
            setOrderNumberErrorMsg("Error: Order number must contain only numbers and dashes.");
            return false;
        }

        // If the input passes the check, clear the error message
        setOrderNumberErrorMsg("");
        return true;
    };

    // // Make sure that our Order Number is at least 8 characters long
    // const validateOrderNumber = (code : string) => {

    //     // Split the string into an array
    //     const split = code.split("-");

    //     if (split.length < 2){

    //         setOrderNumberErrorMsg("Error: Order Number should contain at least 1 dash");
    //         return false;

    //     }

    //     if (code.length >= 8){

    //         setOrderNumberErrorMsg("");
    //         return true;

    //     }else{

    //         setOrderNumberErrorMsg("Error: Order Number must be at least 8 characters long");
    //         return false;

    //     }
    // };

    // Validate our fields on the front end, and then submit them in order to validate them server
    // If validation server side fails, then return an error

    // Prefix Validation
    const validatePrefix = () => {
        if (!orderNumberPrefix || orderNumberPrefix === "") {
            setIsPrefixValid(false);
            return false;
        } else {
            setIsPrefixValid(true);
            return true;
        }
    };

    const validateCodeLength = (code: string) => {

        if (code.length >= 10 && code.length <= 11) {
            setActivationCodeErrorMsg("");
            return true;
        } else {
            setActivationCodeErrorMsg("Error: Activation code must be 10 to 11 characters long");
            return false;
        }
    };

    const handlePrefixChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedPrefix = e.target.value;
        setOrderNumberPrefix(selectedPrefix);


        // Additional logic for setting the prefix message
        if (selectedPrefix === "SC-") {
            setPrefixMessage("www.sensitivitycheck.com");
        } else if (selectedPrefix === "SC-US-") {
            setPrefixMessage("www.sensitivitycheck.co");
        } else if (selectedPrefix === "SC-CA-") {
            setPrefixMessage("www.sensitivitycheckcanada.com");
        } else if (selectedPrefix === "SC-IE-") {
            setPrefixMessage("www.sensitivitycheckireland.com");
        } else if (selectedPrefix === "SC-NZ-") {
            setPrefixMessage("www.sensitivitychecknewzealand.com");
        } else if (selectedPrefix === "SC-AU-") {
            setPrefixMessage("www.sensitivitycheckaustralia.com");
        } else if (selectedPrefix === "TYI-") {
            setPrefixMessage("www.testyourintolerance.com");
        } else if (selectedPrefix === "AT-IE-") {
            setPrefixMessage("www.allergytestireland.com");
        } else if (selectedPrefix === "AT-") {
            setPrefixMessage("www.allergytest.co");
        } else if (selectedPrefix === "TYI-CA-") {
            setPrefixMessage("www.testyourintolerance.ca");
        } else if (selectedPrefix === "TYI-NZ-") {
            setPrefixMessage("www.testyourintolerance.co.nz");
        } else if (selectedPrefix === "AT-AU-") {
            setPrefixMessage("www.allergytestaustralia.com");
        } else if (selectedPrefix === "TMFS-") {
            setPrefixMessage("www.testmyfoodsensitivity.com");
        } else if (selectedPrefix === "TMP-UK-") {
            setPrefixMessage("www.testmypets.co.uk");
        } else if (selectedPrefix === "TMP-US-") {
            setPrefixMessage("www.pethealthathome.com");
        } else if (selectedPrefix === "TMP-CA-") {
            setPrefixMessage("www.pethealthathome.ca");
        } else if (selectedPrefix === "TMP-IE-") {
            setPrefixMessage("www.testmypetsireland.com");
        } else if (selectedPrefix === "AMZ-") {
            setPrefixMessage("Amazon");
        } else if (selectedPrefix === "TMP-UK-") {
            setPrefixMessage("www.testmypets.co.uk");
        } else if (selectedPrefix === "TMP-US-") {
            setPrefixMessage("www.pethealthathome.com");
        } else if (selectedPrefix === "TMP-CA-") {
            setPrefixMessage("www.pethealthathome.ca");
        } else if (selectedPrefix === "TMP-IE-") {
            setPrefixMessage("www.testmypetsireland.com");
        } else if (selectedPrefix === "AMZ-") {
            setPrefixMessage("Amazon");
        } else {
            setPrefixMessage(""); // Reset the message if the prefix doesn't match
        }


    };

    const submitHandler = (event: FormEvent) => {

        event.preventDefault();

        // We set this to true for our recaptcha
        setFormSubmitted(true);

        let orderNumberValid, firstNameValid, lastNameValid, postcodeValid, emailValid, confirmEmailValid, tokenValid, validActivationCode, prefixValid: boolean = false;

        prefixValid = validatePrefix();

        if (orderNumberRef.current) {
            orderNumberValid = validateOrderNumber(orderNumberRef.current.value);
            setIsOrderNumberValid(orderNumberValid);
        }

        //  Validate inputs on the front end
        if (firstNameRef.current) {
            firstNameValid = validateInput(firstNameRef.current.value);
            setIsFirstNameValid(firstNameValid);
        }

        if (lastNameRef.current) {
            lastNameValid = validateInput(lastNameRef.current.value);
            setIsLastNameValid(lastNameValid);
        }

        if (postcodeRef.current) {
            postcodeValid = validateRegionalPostcode(postcodeRef.current.value)
            setIsPostcodeValid(postcodeValid);
        }

        if (emailRef.current) {
            emailValid = validateEmail(emailRef.current.value);
            setIsEmailValid(emailValid);
        }

        // Check the length of the activation code pre submission
        if (activationCodeRef.current) {
            validActivationCode = validateCodeLength(activationCodeRef.current.value);
            setIsActivationCodeValid(validActivationCode);
        }

        if (emailRef.current && secondEmailRef.current) {
            confirmEmailValid = (emailRef.current.value === secondEmailRef.current.value);
            setIsSecondEmailValid(confirmEmailValid);
        }

        if (reCaptchaRef.current) {
            const token = reCaptchaRef.current.getValue();

            // If token isn't null ( which it won't be )
            if (token) {
                tokenValid = validateInput(token);
                setIsReCaptchaValid(tokenValid);
            } else {
                setIsReCaptchaValid(false);
            }

        }

        // If our inputs are valid, submit the form
        if (firstNameValid && lastNameValid && orderNumberValid && postcodeValid && emailValid && confirmEmailValid && validActivationCode && tokenValid && prefixValid) {

            // If we haven't successfully submitted an activation form, submit one. We do this to prevent spamming the submit button
            if (formSuccess === false) {
                console.log("Form event submission");

                saveToDatabase();
            }
        }

    }

    // Query the backend in so we can send a token to LIMS
    const saveToDatabase = async () => {

        // Format date into YYYY-MM-DD
        let formattedDate: string = dateOfBirth?.toISOString().slice(0, 10) ?? "";

        // Get recaptcha value
        let captchaToken;
        let token: string = "";

        if (reCaptchaRef.current) {
            captchaToken = reCaptchaRef.current.getValue();

            if (captchaToken !== null) {
                token = captchaToken;
            }
        }

        // Submit our form to the backend of LIMS using an api request, if it's successful, we'll toggle a pop up to let the customer know that they were successful
        const fields = new FormData();
        orderNumberRef.current && fields.append("orderNumber", orderNumberPrefix + orderNumberRef.current.value);
        firstNameRef.current && fields.append("firstName", firstNameRef.current.value);
        lastNameRef.current && fields.append("lastName", lastNameRef.current.value);
        emailRef.current && fields.append("email", emailRef.current.value);
        activationCodeRef.current && fields.append("activationCode", activationCodeRef.current.value);
        postcodeRef.current && fields.append("postcode", postcodeRef.current.value);
        dateOfBirth && fields.append("dateOfBirth", formattedDate);
        reCaptchaRef.current && fields.append("recaptchaToken", token);
        genderRef.current && fields.append("gender", genderRef.current.value);
        fields.append("phoneNumber", phoneNumber ? phoneNumber : "");

        console.log("Order ref value");
        console.log(orderNumberRef.current && orderNumberRef.current.value);

        // Perform API request
        // Note: Do not pass a content type through to this request as the browser completes for you as well as your boundary
        const response = await fetch(endpoint, {
            method: "POST",
            body: fields
        });

        const data = await response.json();

        console.log(data);

        // Set form submission to true
        setFormSuccess(data.success);

        if (data.issue === "recaptcha") {
            setIsReCaptchaValid(false);
        }

        // Show popup after form submission whether it succeeds or fails
        setShowPopup(true);

        if (data.success === true) {
            navigate(`/thank-you-hair/${orderNumberPrefix}${orderNumberRef.current ? orderNumberRef.current.value : ""}/${firstNameRef.current ? firstNameRef.current.value : ""}-${lastNameRef.current ? lastNameRef.current.value : ""}`);
        }

    };

    const handleActivationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const activationCode = e.target.value;

        let isValidPrefix = false;
        let sanitizedCode = activationCode;

        // Check if the activation code starts with certain characters
        if (activationCode.startsWith("11")) {
            setOrderNumberPrefix("TMP-CA-");
            setPrefixMessage("www.pethealthathome.ca");
            isValidPrefix = true;
        } else if (activationCode.startsWith("12")) {
            setOrderNumberPrefix("TMP-IE-");
            setPrefixMessage("www.testmypetsireland.com");
            isValidPrefix = true;
        } else if (activationCode.startsWith("1") || activationCode.startsWith("2")) {
            setOrderNumberPrefix("AMZ-");
            setPrefixMessage("Amazon");
            isValidPrefix = true;
        } else if (activationCode.startsWith("B") || activationCode.startsWith("b")) {
            setOrderNumberPrefix("TYI-");
            setPrefixMessage("www.testyourintolerance.com");
            isValidPrefix = true;
        } else if (activationCode.startsWith("S") || activationCode.startsWith("s")) {
            setOrderNumberPrefix("TYI-NZ-");
            setPrefixMessage("www.testyourintolerance.co.nz");
            isValidPrefix = true;
        } else if (activationCode.startsWith("P") || activationCode.startsWith("p")) {
            setOrderNumberPrefix("TYI-CA-");
            setPrefixMessage("www.testyourintolerance.ca");
            isValidPrefix = true;
        } else if (activationCode.startsWith("O") || activationCode.startsWith("o")) {
            setOrderNumberPrefix("AT-AU-");
            setPrefixMessage("www.allergytestaustralia.com");
            isValidPrefix = true;
        } else if (activationCode.startsWith("N") || activationCode.startsWith("n")) {
            setOrderNumberPrefix("AT-");
            setPrefixMessage("www.allergytest.co");
            isValidPrefix = true;
        } else if (activationCode.startsWith("Q") || activationCode.startsWith("q")) {
            setOrderNumberPrefix("AT-IE-");
            setPrefixMessage("www.allergytestireland.com");
            isValidPrefix = true;
        } else if (activationCode.startsWith("Z") || activationCode.startsWith("z")) {
            setOrderNumberPrefix("SC-");
            setPrefixMessage("www.sensitivitycheck.com");
            isValidPrefix = true;
        // } else if (activationCode.startsWith("3")) {
        //     setOrderNumberPrefix("SC-US-");
        //     setPrefixMessage("www.sensitivitycheck.co");
        //     isValidPrefix = true;
        } else if (activationCode.startsWith("5")) {
            setOrderNumberPrefix("SC-CA-");
            setPrefixMessage("www.sensitivitycheckcanada.com");
            isValidPrefix = true;
        } else if (activationCode.startsWith("4")) {
             setOrderNumberPrefix("SC-AU-");
             setPrefixMessage("www.sensitivitycheckaustralia.com");
             isValidPrefix = true;
        } else if (activationCode.startsWith("6")) {
            setOrderNumberPrefix("SC-IE-");
            setPrefixMessage("www.sensitivitycheckireland.com");
            isValidPrefix = true;
        } else if (activationCode.startsWith("7")) {
            setOrderNumberPrefix("SC-NZ-");
            setPrefixMessage("www.sensitivitychecknewzealand.com");
            isValidPrefix = true;
        } else if (activationCode.startsWith("8")) {
            setOrderNumberPrefix("TMP-UK-");
            setPrefixMessage("www.testmypets.co.uk");
            isValidPrefix = true;
        } else if (activationCode.startsWith("9")) {
            setOrderNumberPrefix("TMP-US-");
            setPrefixMessage("www.pethealthathome.com");
            isValidPrefix = true;
        } else if (activationCode.startsWith("W") || activationCode.startsWith("w")) {
            setOrderNumberPrefix("TMFS-");
            setPrefixMessage("www.testmyfoodsensitivity.com");
            isValidPrefix = true;
        } else {
            // If the activation code doesn't start with any recognized characters, clear the prefix and message
            setOrderNumberPrefix("");
            setPrefixMessage("");
        }
        // Remove the first dash from the activation code if a valid prefix is detected
        if (isValidPrefix && activationCode.includes("-")) {
            const prefixLength = orderNumberPrefix.length; // Get the length of the prefix
            sanitizedCode = activationCode.substring(prefixLength); // Remove prefix
            sanitizedCode = sanitizedCode.replace('-', ''); // Remove the first dash
        }

        // Set the activation code input value
        e.target.value = sanitizedCode;

        // Set activation code error message and validity only if it doesn't match any recognized prefixes
        if (!isValidPrefix) {
            setActivationCodeErrorMsg("Please enter a valid activation code.");
            setIsActivationCodeValid(false);
        } else {
            setActivationCodeErrorMsg("Your order prefix has been selected.");
            setIsActivationCodeValid(true);
        }
    };

    return (
        <section className="hair-form-wrapper">

            <NavLink to="/" className="hair-form-link"><BsArrowLeftSquareFill />Go Back</NavLink>
            <NavLink to="/hair-retest" className="hair-form-link"><BsArrowLeftSquareFill />Submit a retest</NavLink>

            {showPopup &&
                <p className={`hair-form-popup ${!formSuccess && 'hair-form-popup--error'}`}>
                    {formSuccess ? "Success! Refresh the page if you wish to submit another code" : "Please fix the errors found below"}
                </p>
            }

            <form className="hair-form" onSubmit={submitHandler}>

                <p className="hair-form__title">Activate Your Test Kit</p>

                <div className={`hair-form__field ${!isFirstNameValid && "hair-form__field--error"}`}>
                    <label id="first_name_label" htmlFor="first_name" className="hair-form__label">{isFirstNameValid ? "First Name*" : "Error: First name is empty"}</label>
                    <input
                        type="text"
                        name="first_name"
                        aria-labelledby="first_name_label"
                        placeholder="Please enter your first name..."
                        aria-required
                        className={`hair-form__input ${!isFirstNameValid && "hair-form__input--error"}`}
                        ref={firstNameRef}
                    />
                </div>

                <div className={`hair-form__field ${!isLastNameValid && "hair-form__field--error"}`}>
                    <label id="last_name_label" htmlFor="last_name" className="hair-form__label">{isLastNameValid ? "Last Name*" : "Error: Last name is empty"}</label>
                    <input
                        type="text"
                        name="last_name"
                        aria-labelledby="last_name_label"
                        placeholder="Please enter your last name..."
                        aria-required
                        className={`hair-form__input ${!isLastNameValid && "hair-form__input--error"}`}
                        ref={lastNameRef}
                    />
                </div>

                <div className="hair-form__field">
                    <p className="hair-form__label">Date Of Birth*</p>
                    <DatePicker
                        selected={dateOfBirth}
                        onChange={(date) => setDateOfBirth(date)}
                        showYearDropdown={true}
                        dateFormat="dd/MM/yyyy"
                    />
                </div>

                <div className="hair-form__field">
                    <label id="gender_label" htmlFor="gender" className="hair-form__label">Gender</label>
                    <select
                        name="gender"
                        aria-labelledby="gender_label"
                        ref={genderRef}
                        defaultValue="default"
                        className="hair-form__dropdown"
                        style={{
                            background: "url('https://lims.healthystuff.com/arrow-down.svg')",
                            backgroundSize: "18px",
                            backgroundRepeat: "no-repeat",
                            backgroundPositionY: "50%",
                            backgroundPositionX: "calc(100% - 5px)"
                        }}
                    >
                        <option key="default" value={"default"} disabled>Choose Gender</option>
                        <option key="male" value={"Male"}>Male</option>
                        <option key="female" value={"Female"}>Female</option>
                        <option key="other" value={"Other"}>Other</option>
                        <option key="neither" value={"Prefer not to say"}>Prefer not to say</option>
                    </select>
                </div>

                <div className={`hair-form__field ${!isPostcodeValid && "hair-form__field--error"}`}>
                    <label id="postcode_label" htmlFor="postcode" className="hair-form__label">{isPostcodeValid ? "Zipcode/Postcode*" : "Error: Zipcode/Postcode isn't valid"}</label>
                    <input
                        type="text"
                        name="postcode"
                        aria-labelledby="postcode_label"
                        aria-required
                        placeholder="Please enter your postcode..."
                        className={`hair-form__input ${!isPostcodeValid && "hair-form__input--error"}`}
                        ref={postcodeRef}
                    />
                </div>

                <div className={`hair-form__field ${!isEmailValid && "hair-form__field--error"}`}>
                    <label id="email_address_label" htmlFor="email_address" className="hair-form__label">{isEmailValid ? "Email Address*" : "Error: Email address isn't valid"}</label>
                    <input
                        type="email"
                        name="email_address"
                        aria-labelledby="email_address_label"
                        aria-required
                        placeholder="Please enter your email address..."
                        className={`hair-form__input ${!isEmailValid && "hair-form__input--error"}`}
                        ref={emailRef}
                    />
                </div>

                <div className={`hair-form__field ${!isSecondEmailValid && "hair-form__field--error"}`}>
                    <label id="second_email_address_label" htmlFor="second_email_address" className="hair-form__label">{isSecondEmailValid ? "Confirm Your Email Address*" : "Error: email addresses don't match"}</label>
                    <input
                        type="email"
                        name="second_email_address"
                        aria-labelledby="second_email_address_label"
                        aria-required
                        placeholder="Please confirm your email address..."
                        className={`hair-form__input ${!isSecondEmailValid && "hair-form__input--error"}`}
                        ref={secondEmailRef}
                    />
                </div>

                <PhoneInput
                    country={'gb'}
                    value={phoneNumber?.toString()}
                    onChange={setPhoneNumber}
                />

                <div className={`hair-form__field ${!isActivationCodeValid && "hair-form__field--error"}`}>
                    <label id="submission_code_label" htmlFor="submission_code" className="blood-form__label">
                        {activationCodeErrorMsg.length > 0 ?
                            activationCodeErrorMsg :
                            "Activation Code (Found on your test kit)*"
                        }
                    </label>
                    <input
                        type="text"
                        name="submission_code"
                        aria-labelledby="submission_code_label"
                        aria-required="true"
                        placeholder="Please enter your Activation Code..."
                        className={`blood-form__input activation-code-input ${!isActivationCodeValid && "blood-form__input--error"}`}
                        onChange={handleActivationCodeChange}
                        ref={activationCodeRef}
                        maxLength={15}
                    />
                    {/* <small className="order-prefix-note"><b>Your Order Number Prefix will auto-fill based on your activation code.</b></small> */}

                </div>

                <div className={`hair-form__field ${(!isOrderNumberValid || !isPrefixValid) && "hair-form__field--error"}`}>
                    <label id="order_number_label" htmlFor="order_number" className="hair-form__label">
                        {!isOrderNumberValid && orderNumberErrorMsg ? orderNumberErrorMsg :
                            !isPrefixValid ? "Error: Activation code doesn't match the prefix." :
                                "Order Number (Found in your confirmation email)*"}
                    </label>
                    <div className="order-number-container">
                        <select
                            name="order_number_prefix"
                            aria-labelledby="order_number_label"
                            className="order-prefix-select"
                            onChange={handlePrefixChange} // Only one onChange handler
                            value={orderNumberPrefix}
                        >
                            <option value="">Prefix</option>
                            {prefixes.map((prefix) => (
                                <option key={prefix} value={prefix}>
                                    {prefix}
                                </option>
                            ))}

                        </select>
                        <input
                            style={{ float: 'left', width: isMobile ? '64%' : '79%' }}
                            type="text"
                            name="order_number"
                            aria-labelledby="order_number_label"
                            placeholder="Enter your order number only here..."
                            aria-required
                            className={`hair-form__input`}
                            ref={orderNumberRef}
                            autoComplete="new-password"
                            onChange={(e) => {
                                let value = e.target.value;

                                // Convert the input to uppercase
                                value = value.toUpperCase();

                                // Remove any non-numeric and non-dash characters
                                value = value.replace(/[^0-9-]/g, '');

                                // Remove any leading prefix followed by a dash
                                value = value.replace(/^([A-Z]{2,}-)+/, '');

                                // Remove any leading dash
                                value = value.replace(/^[-]+/, '');

                                // Update the input field value
                                e.target.value = value;
                            }}
                        />
                    </div>
                    {/* Display the prefix message below the select element */}
                    {prefixMessage && (
                        <p className="prefix-message">
                            The site that you have ordered from is <br />
                            <strong>{prefixMessage}</strong>
                        </p>
                    )}
                </div>

                <Checkbox
                    isChecked={consentChecked}
                    label="I agree for my data to be used for research and publication purposes."
                    handleChange={handleCheckboxToggle}
                />

                {
                    formSubmitted && (isReCaptchaValid === false) &&
                    <p className="hair-form__recaptcha-error">Error: ReCaptcha is invalid</p>
                }
                <ReCAPTCHA
                    sitekey="6Ld_2pskAAAAAPFePhfrk2K7T3mVksszVs9XF16X"
                    ref={reCaptchaRef}
                />

                <button className="hair-form__submit">Submit</button>

            </form>

        </section>
    );
};

export default HairForm;

