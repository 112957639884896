/**
 * Blood Form component.
 * Takes form data and validates it.
 * If successful, an api request is sent to './api/BloodSubmission.php'.
 * 
 * @method validateInput : (string) => boolean
 * @method validateEmail : (string) => boolean
 * @method validateCodeLength : (string) => boolean
 * @method queryLIMS : async () => void
 * @method formatResults : (QueryInterface) => void
 * @method submitHandler : (React.FormEvent) => void
 * 
 * @returns ActivationCode : PureComponent
 */

import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { BsArrowLeftSquareFill } from "react-icons/bs";
import ReCAPTCHA from "react-google-recaptcha";
import "./BloodForm.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Checkbox from "../inputs/Checkbox";
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';

//endpoints
// Dev
// const endpoint = "http://localhost:80/activatemytest/api/controllers/BloodSubmission.php";
// Staging
// const endpoint = "https://staging.activatemytest.com/api/controllers/BloodSubmission.php";
// Live
const endpoint = "https://activatemytest.com/api/controllers/BloodSubmission.php";

// Interfaces for the results we get
interface SubmissionResponse {
    success: boolean,
    message: string
};

interface QueryInterface {
    codeActivated: boolean | null,
    codeExists: boolean,
    error: boolean,
    message: string,
    orderValid: boolean,
    result: SubmissionResponse | null
};

interface QueryResult {
    error: boolean,
    result: QueryInterface | null,
    qrCodeSaved: boolean,
    location: string
};

interface QueryResult {
    error: boolean,
    result: QueryInterface | null,
    qrCodeSaved: boolean,
    location: string
};

// Check the prefix against the array of partners
// const checkPrefix = (prefix : string) => {

//     // Partners prefixes
//     const partners = [
//         "AA"
//     ];

//     // Get a list of filtered partners, if the length is > 0, return true, otherwise return false
//     const filteredPartners = partners.filter((partner : string) => {
//         return partner === prefix.toUpperCase();
//     });

//     if (filteredPartners.length > 0) {
//         return true;
//     }

//     return false;

// };

const BloodForm = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // navigate to redirect on form submission
    const navigate = useNavigate();

    if (postcodeValidatorExistsForCountry("GB") === false) {

        throw new Error("Warning, postcode validator doesn't work. Please check the package");
    }

    // Input refs
    const firstNameRef = useRef<HTMLInputElement>(null);
    const lastNameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const secondEmailRef = useRef<HTMLInputElement>(null);
    const [orderNumberPrefix, setOrderNumberPrefix] = useState("");
    const prefixes = [
        "TYI-",
        "TYI-NZ-",
        "TYI-CA-",
        "AT-",
        "AT-AU-",
        "AT-IE-",
        "TMA-",
        "TMIT-",
        "WH-",
        "AMZ-",
        "HS-",
    ];
    const [prefixMessage, setPrefixMessage] = useState(""); // State to hold the dynamic message
    const orderNumberRef = useRef<HTMLInputElement>(null);
    const activationCodeRef = useRef<HTMLInputElement>(null);
    const partnerCodeRef = useRef<HTMLInputElement>(null);
    const reCaptchaRef = useRef<ReCAPTCHA>(null);
    const genderRef = useRef<HTMLSelectElement>(null);
    const postcodeRef = useRef<HTMLInputElement>(null);

    // Form validation states
    const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true);
    const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);
    const [isPostcodeValid, setIsPostcodeValid] = useState<boolean>(true);
    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(new Date("2000-01-01"));
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const [isSecondEmailValid, setIsSecondEmailValid] = useState<boolean>(true);
    const [isOrderNumberValid, setIsOrderNumberValid] = useState<boolean>(true);
    const [isActivationCodeValid, setIsActivationCodeValid] = useState<boolean>(true);
    const [isPartnerCodeValid, setIsPartnerCodeValid] = useState<boolean>(true);
    const [activationCodeErrorMsg, setActivationCodeErrorMsg] = useState<string>("");
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [formSuccess, setFormSuccess] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    // const [showPartnerField, setShowPartnerField] = useState<boolean>(false);
    const [isReCaptchaValid, setIsReCaptchaValid] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [consentChecked1, setConsentChecked1] = useState<boolean>(false);
    const [isPrefixValid, setIsPrefixValid] = useState(true);

    const validateRegionalPostcode = (postcode: string) => {

        let postcodeIsValid = false;

        // Postcodes array, we validate these to make the validator "global"
        const postcodes = [
            "GB",
            "US",
            "AU",
            "CA",
            "DE",
            "NL",
            "PL",
            "FI",
            "FR",
            "DE",
            "CH",
            "CN",
            "SE",
            "IE",
            "LU",
            "NO",
            "BE",
            "AT",
            "PL",
            "SG",
            "KR",
            "NZ",
            "JP",
            "MY",
            "AR",
            "ZA",
            "TW",
            "TH"
        ];

        // Loop through the array of postcodes to match the postcode
        // If true, we return true at the end of this function, otherwise, it stays false
        // eslint-disable-next-line array-callback-return
        postcodes.map((code: string) => {

            if (postcodeValidator(postcode, code) === true) {
                postcodeIsValid = true;
            }
        });

        return postcodeIsValid;
    };

    // Toggle checkbox
    const handleCheckboxToggle1 = () => {
        setConsentChecked1(!consentChecked1);
    };

    // Validate our email address as being valid by checking it against regular expression
    const validateEmail = (email: string) => {
        return /^[^@]+@[^@]+\.[^@]+$/.test(email);
    };

    // Make sure the input is not empty
    const validateInput = (input: string) => {
        return input.length > 0;
    };

    // Make sure the product length is 2 characters
    const validatePartnerCode = (input: string) => {
        return input.length === 2;
    };

    // Prefix Validation
    const validatePrefix = () => {
        if (!orderNumberPrefix || orderNumberPrefix === "") {
            setIsPrefixValid(false);
            return false;
        } else {
            setIsPrefixValid(true);
            return true;
        }
    };

    // Make sure that our activation code is at least 8 characters long
    const validateCodeLength = (code: string) => {

        if (code.length >= 8) {

            setActivationCodeErrorMsg("");
            return true;

        } else {

            setActivationCodeErrorMsg("Error: Activation code must be at least 8 characters long");
            return false;

        }
    };



    // // Determine if we show the partner code dynamically based on the current length of the activation code
    // const checkIfPartner = () => {

    //     if (activationCodeRef.current && activationCodeRef.current.value.length >= 2) {

    //         // Get a substring and check the refix in order to show or hide the partner code field
    //         const prefix = activationCodeRef.current.value.substring(0, 2);
    //         setShowPartnerField(checkPrefix(prefix));
    //     }else{
    //         setShowPartnerField(false);
    //     }

    // };

    // Submit our form to the backend of LIMS using an api request, if it's successful, we'll toggle a pop up to let the customer know that they were successful
    const queryLIMS = async () => {

        let formattedDate: string = dateOfBirth?.toISOString().slice(0, 10) ?? "";

        const token = String(reCaptchaRef.current?.getValue());

        const fields = new FormData();
        firstNameRef.current && fields.append("firstName", firstNameRef.current.value);
        lastNameRef.current && fields.append("lastName", lastNameRef.current.value);
        emailRef.current && fields.append("email", emailRef.current.value);
        orderNumberRef.current && fields.append("orderNumber", orderNumberPrefix + orderNumberRef.current.value);
        activationCodeRef.current && fields.append("activationCode", activationCodeRef.current.value);
        postcodeRef.current && fields.append("postcode", postcodeRef.current.value);
        dateOfBirth && fields.append("dateOfBirth", formattedDate);
        partnerCodeRef.current && fields.append("partnerCode", partnerCodeRef.current.value ? partnerCodeRef.current.value : "");
        reCaptchaRef.current && fields.append("recaptchaToken", token);
        genderRef.current && fields.append("gender", genderRef.current.value);
        fields.append("phoneNumber", phoneNumber ? phoneNumber : "");

        // Perform API request
        // Note: Do not pass a content type through to this request as the browser completes for you as well as your boundary
        const response = await fetch(endpoint, {
            method: "POST",
            body: fields
        });

        const data = await response.json();

        console.log(data);

        formatResults(data);

    }

    // Format the results for the popup responses
    const formatResults = (response: QueryResult) => {

        const result = response.result;

        let orderValid, codeExists, codeActivated, tokenValid: boolean = false;

        console.log("\n");

        // Set order valid boolean
        // orderValid = result?.orderValid ? result?.orderValid : false;
        orderValid = true;

        // Set activation code booleans
        codeExists = result?.codeExists;
        codeActivated = result?.codeActivated ? result?.codeActivated : false;

        // Set order validity state
        setIsOrderNumberValid(orderValid);

        // Set activation code validity state
        setIsActivationCodeValid(() => {

            // If our code doesn't exist
            if (result?.codeExists === false) {
                setActivationCodeErrorMsg("Error: Activation code doesn't exist.");
                return false;
            }

            if (result?.codeActivated === true) {
                setActivationCodeErrorMsg("Error: Activation code has already been used.");
                return false;
            }

            if (response.qrCodeSaved === false) {
                setActivationCodeErrorMsg("Error: You cannot submit more activation codes. Please contact customer support");
                return false;
            }

            setActivationCodeErrorMsg("");
            return true;

        });

        if (response.location === "recaptcha" && response.error === true) {

            tokenValid = false;
            setIsReCaptchaValid(tokenValid);
        } else {

            tokenValid = true;
            setIsReCaptchaValid(tokenValid);
        }

        if (orderValid === true && codeExists === true && codeActivated === false && tokenValid === true) {

            // Set the form to green
            setFormSuccess(true);

            // Reset re-captcha
            reCaptchaRef.current?.reset();

            // Navigate to the blood thank you page
            navigate(`/thank-you-blood/`);

        }

    }

    // On submission, set the isSubmitted state to true, and then execute validation on the fields. All fields are now automatically validated.
    const submitHandler = (event: React.FormEvent) => {

        // Stop the page from reloading
        event.preventDefault();

        // We define local values here because we need to trigger a re-render by updating state which means that we won't be able to access state validation
        let validFirstName, validLastName, postcodeValid, validEmail, confirmEmailValid, validActivationCode, validOrderNumber, validPartnerCode, tokenValid, prefixValid: boolean = true;

        prefixValid = validatePrefix();

        // Validate our inputs
        if (firstNameRef.current) {
            validFirstName = validateInput(firstNameRef.current.value);
            setIsFirstNameValid(validFirstName);
        }

        if (lastNameRef.current) {
            validLastName = validateInput(lastNameRef.current.value);
            setIsLastNameValid(validLastName);
        }

        if (postcodeRef.current) {
            postcodeValid = validateRegionalPostcode(postcodeRef.current.value)
            setIsPostcodeValid(postcodeValid);
        }

        if (emailRef.current) {
            validEmail = validateEmail(emailRef.current.value)
            setIsEmailValid(validEmail);
        }

        // Check the length of the activation code pre submission
        if (activationCodeRef.current) {
            validActivationCode = validateCodeLength(activationCodeRef.current.value);
            setIsActivationCodeValid(validActivationCode);
        }

        if (emailRef.current && secondEmailRef.current) {
            confirmEmailValid = (emailRef.current.value === secondEmailRef.current.value);
            setIsSecondEmailValid(confirmEmailValid);
        }

        if (orderNumberRef.current) {
            validOrderNumber = validateInput(orderNumberRef.current.value);
            setIsOrderNumberValid(validOrderNumber);
        }

        if (partnerCodeRef.current) {
            validPartnerCode = validatePartnerCode(partnerCodeRef.current.value);
            setIsPartnerCodeValid(validPartnerCode);
        }

        if (reCaptchaRef.current) {
            const token = reCaptchaRef.current.getValue();

            console.log("token", token);

            // If token isn't null ( which it won't be )
            if (token) {

                tokenValid = validateInput(token);
                setIsReCaptchaValid(tokenValid);

            } else {

                setIsReCaptchaValid(false);

            }
        }

        if (validFirstName && validLastName && validEmail && validActivationCode && tokenValid && prefixValid && validOrderNumber) {

            // If we haven't successfully submitted an activation form, submit one. We do this to prevent spamming the submit button
            if (formSuccess === false) {
                console.log("Form event submission");
                queryLIMS();
            }

        }

        setShowPopup(true);

    };

    const handlePrefixChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedPrefix = e.target.value;
        setOrderNumberPrefix(selectedPrefix);

        // Additional logic for setting the prefix message
        if (selectedPrefix === "TYI-") {
            setPrefixMessage("www.testyourintolerance.com");
        } else if (selectedPrefix === "TYI-NZ-") {
            setPrefixMessage("www.testyourintolerance.co.nz");
        } else if (selectedPrefix === "TYI-CA-") {
            setPrefixMessage("www.testyourintolerance.ca");
        } else if (selectedPrefix === "AT-") {
            setPrefixMessage("www.allergytest.co");
        } else if (selectedPrefix === "AT-AU-") {
            setPrefixMessage("www.allergytestaustralia.com");
        } else if (selectedPrefix === "AT-IE-") {
            setPrefixMessage("www.allergytestireland.com");
        } else if (selectedPrefix === "TMA-") {
            setPrefixMessage("www.testmyallergy.com");
        } else if (selectedPrefix === "TMIT-") {
            setPrefixMessage("www.tmitesting.com");
        } else if (selectedPrefix === "WH-") {
            setPrefixMessage("www.weareahealthier.com");
        } else if (selectedPrefix === "HS-") {
            setPrefixMessage("www.Healthystuff.com");
        } else if (selectedPrefix === "AMZ-") {
            setPrefixMessage("Amazon");
        } else {
            setPrefixMessage(""); // Reset the message if the prefix doesn't match
        }

    };

    // Check if the form is valid for the popup
    useEffect(() => {

        // If our inputs are valid, then set the pop up to show success text
        if (isOrderNumberValid === true && isFirstNameValid === true && isLastNameValid === true && isEmailValid === true && isPostcodeValid === true && isActivationCodeValid === true && isPartnerCodeValid === true && isReCaptchaValid === true) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }

    }, [isOrderNumberValid, isFirstNameValid, isLastNameValid, isEmailValid, isActivationCodeValid, isPostcodeValid, isPartnerCodeValid, isReCaptchaValid, isPrefixValid]);

    const handleActivationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const activationCode = e.target.value;

        let isValidPrefix = false;
        let sanitizedCode = activationCode;

        // Check if the activation code starts with "1" or "2"
        if (activationCode.startsWith("1") || activationCode.startsWith("2")) {
            setOrderNumberPrefix("AMZ-");
            setPrefixMessage("Amazon");
            isValidPrefix = true;
        } else if (activationCode.startsWith("B") || activationCode.startsWith("b")) {
            setOrderNumberPrefix("TYI-");
            setPrefixMessage("www.testyourintolerance.com");
            isValidPrefix = true;
        } else if (activationCode.startsWith("S") || activationCode.startsWith("s")) {
            setOrderNumberPrefix("TYI-NZ-");
            setPrefixMessage("www.testyourintolerance.co.nz");
            isValidPrefix = true;
        } else if (activationCode.startsWith("P") || activationCode.startsWith("p")) {
            setOrderNumberPrefix("TYI-CA-");
            setPrefixMessage("www.testyourintolerance.ca");
            isValidPrefix = true;
        } else if (activationCode.startsWith("O") || activationCode.startsWith("o")) {
            setOrderNumberPrefix("AT-AU-");
            setPrefixMessage("www.allergytestaustralia.com");
            isValidPrefix = true;
        } else if (activationCode.startsWith("N") || activationCode.startsWith("n")) {
            setOrderNumberPrefix("AT-");
            setPrefixMessage("www.allergytest.co");
            isValidPrefix = true;
        } else if (activationCode.startsWith("Q") || activationCode.startsWith("q")) {
            setOrderNumberPrefix("AT-IE-");
            setPrefixMessage("www.allergytestireland.com");
            isValidPrefix = true;
        } else if (activationCode.startsWith("U") || activationCode.startsWith("u")) {
            setOrderNumberPrefix("TMA-");
            setPrefixMessage("www.testmyallergy.com");
            isValidPrefix = true;
        } else if (activationCode.startsWith("T") || activationCode.startsWith("t")) {
            setOrderNumberPrefix("TMIT-");
            setPrefixMessage("www.tmitesting.com");
            isValidPrefix = true;
        } else if (activationCode.startsWith("E") || activationCode.startsWith("e")) {
            setOrderNumberPrefix("WH-");
            setPrefixMessage("www.wearehealthier.com");
            isValidPrefix = true;
        } else if (activationCode.startsWith("M") || activationCode.startsWith("m")) {
            setOrderNumberPrefix("HS-");
            setPrefixMessage("www.healthystuff.com");
            isValidPrefix = true;
        } else {
            // If the activation code doesn't start with any recognized characters, clear the prefix and message
            setOrderNumberPrefix("");
            setPrefixMessage("");
        }
        // Remove the first dash from the activation code if a valid prefix is detected
        if (isValidPrefix && activationCode.includes("-")) {
            const prefixLength = orderNumberPrefix.length; // Get the length of the prefix
            sanitizedCode = activationCode.substring(prefixLength); // Remove prefix
            sanitizedCode = sanitizedCode.replace('-', ''); // Remove the first dash
        }

        // Set the activation code input value
        e.target.value = sanitizedCode;

        // Set activation code error message and validity only if it doesn't match any recognized prefixes
        if (!isValidPrefix) {
            setActivationCodeErrorMsg("Please enter a valid activation code.");
            setIsActivationCodeValid(false);
        } else {
            setActivationCodeErrorMsg("Your order prefix has been selected.");
            setIsActivationCodeValid(true);
        }
    };


    return (
        <section className="blood-form-wrapper">

            <NavLink to="/" className="blood-form-link"><BsArrowLeftSquareFill />Go Back</NavLink>

            {showPopup &&
                <p className="blood-form-popup">
                    {isFormValid ? "Success! Refresh the page if you wish to submit another code " : "Please fix the errors found below"}
                </p>
            }

            <form className="blood-form" onSubmit={submitHandler}>

                <p className="blood-form__title">Activate Your Test Kit</p>

                <div className={`blood-form__field ${!isFirstNameValid && "blood-form__field--error"}`}>
                    <label id="first_name_label" htmlFor="first_name" className="blood-form__label">{isFirstNameValid ? "First Name*" : "Error: First name is empty"}</label>
                    <input
                        type="text"
                        name="first_name"
                        aria-labelledby="first_name_label"
                        placeholder="Please enter your first name..."
                        aria-required
                        className={`blood-form__input ${!isFirstNameValid && "blood-form__input--error"}`}
                        ref={firstNameRef}
                    />
                </div>

                <div className={`blood-form__field ${!isLastNameValid && "blood-form__field--error"}`}>
                    <label id="last_name_label" htmlFor="last_name" className="blood-form__label">{isLastNameValid ? "Last Name*" : "Error: Last name is empty"}</label>
                    <input
                        type="text"
                        name="last_name"
                        aria-labelledby="last_name_label"
                        placeholder="Please enter your last name..."
                        aria-required
                        className={`blood-form__input ${!isLastNameValid && "blood-form__input--error"}`}
                        ref={lastNameRef}
                    />
                </div>

                <div className="blood-form__field">
                    <label className="blood-form__label">Date Of Birth*</label>
                    <DatePicker
                        selected={dateOfBirth}
                        onChange={(date) => setDateOfBirth(date)}
                        showYearDropdown={true}
                        dateFormat="dd/MM/yyyy"
                    />
                </div>

                <div className="blood-form__field">
                    <label id="gender_label" htmlFor="gender" className="blood-form__label blood-form__label--dob">Gender</label>
                    <select
                        name="gender"
                        aria-labelledby="gender_label"
                        ref={genderRef}
                        defaultValue="default"
                        className="blood-form__dropdown"
                        style={{
                            background: "url('https://lims.healthystuff.com/arrow-down.svg')",
                            backgroundSize: "18px",
                            backgroundRepeat: "no-repeat",
                            backgroundPositionY: "50%",
                            backgroundPositionX: "calc(100% - 5px)"
                        }}
                    >
                        <option key="default" value={"default"} disabled>Choose Gender</option>
                        <option key="male" value={"Male"}>Male</option>
                        <option key="female" value={"Female"}>Female</option>
                        <option key="other" value={"Other"}>Other</option>
                        <option key="neither" value={"Prefer not to say"}>Prefer not to say</option>
                    </select>
                </div>

                <div className={`blood-form__field ${!isPostcodeValid && "blood-form__field--error"}`}>
                    <label id="postcode_label" htmlFor="postcode" className="blood-form__label">{isPostcodeValid ? "Zipcode/Postcode*" : "Error: Zipcode/Postcode isn't valid"}</label>
                    <input
                        type="text"
                        name="postcode"
                        aria-labelledby="postcode_label"
                        aria-required
                        placeholder="Please enter your postcode..."
                        className={`blood-form__input ${!isPostcodeValid && "blood-form__input--error"}`}
                        ref={postcodeRef}
                    />
                </div>

                <div className={`blood-form__field ${!isEmailValid && "blood-form__field--error"}`}>
                    <label id="email_address_label" htmlFor="email_address" className="blood-form__label">{isEmailValid ? "Email Address*" : "Error: Email address isn't valid"}</label>
                    <input
                        type="email"
                        name="email_address"
                        aria-labelledby="email_address_label"
                        aria-required
                        placeholder="Please enter your email address..."
                        className={`blood-form__input ${!isEmailValid && "blood-form__input--error"}`}
                        ref={emailRef}

                    />
                </div>

                <div className={`blood-form__field ${!isSecondEmailValid && "blood-form__field--error"}`}>
                    <label id="second_email_address_label" htmlFor="second_email_address" className="blood-form__label">{isSecondEmailValid ? "Confirm Your Email Address*" : "Error: email addresses don't match"}</label>
                    <input
                        type="email"
                        name="second_email_address"
                        aria-labelledby="second_email_address_label"
                        aria-required
                        placeholder="Please confirm your email address..."
                        className={`blood-form__input ${!isSecondEmailValid && "blood-form__input--error"}`}
                        ref={secondEmailRef}
                    />
                </div>

                <PhoneInput
                    country={'gb'}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                />

                <div className={`blood-form__field ${!isActivationCodeValid && "blood-form__field--error"}`}>
                    <label id="activation_code_label" htmlFor="activation_code" className="blood-form__label">
                        {activationCodeErrorMsg.length > 0 ? activationCodeErrorMsg : "Activation Code (Found on your test kit)*"}
                    </label>
                    <input
                        type="text"
                        name="activation_code"
                        aria-labelledby="activation_code_label"
                        aria-required
                        placeholder="Please enter your activation code..."
                        className={`blood-form__input activation-code-input ${!isActivationCodeValid && "blood-form__input--error"}`}
                        onChange={handleActivationCodeChange}
                        ref={activationCodeRef}
                        maxLength={10}
                    />
                    <small className="order-prefix-note"><b>Your Order Number Prefix will auto-fill based on your activation code.</b></small>
                </div>

                <div className={`blood-form__field ${!isOrderNumberValid || !isPrefixValid ? "blood-form__field--error" : ""}`}>
                    <label id="order_number_label" htmlFor="order_number" className="blood-form__label">
                        {isPrefixValid ? (isOrderNumberValid ? "Order Number (Found on your confirmation email)*" : "Error: Order number isn't valid") : "Error: Activation code doesn't match the prefix."}
                    </label>
                    <div className="order-number-container">
                        <select
                            name="order_number_prefix"
                            aria-labelledby="order_number_label"
                            className="order-prefix-select"
                            onChange={handlePrefixChange} // Only one onChange handler
                            value={orderNumberPrefix}
                        >
                            <option value="" disabled>Prefix</option>
                            {prefixes.map((prefix) => (
                                <option key={prefix} value={prefix} disabled={true}>
                                    {prefix}
                                </option>
                            ))}
                        </select>
                        <input
                            style={{ float: 'left', width: isMobile ? '68%' : '81%' }}
                            type="text"
                            name="order_number"
                            aria-required
                            placeholder="Enter your order number..."
                            className={`blood-form__input ${!isOrderNumberValid && "blood-form__input--error"}`}
                            ref={orderNumberRef}
                            autoComplete="new-password"
                            pattern="[0-9-]*"  // This allows only numbers and dashes
                            title="Order number must contain only numbers and dashes."
                            onChange={(e) => {
                                let value = e.target.value;
                            
                                // Convert the input to uppercase
                                value = value.toUpperCase();
                            
                                // Remove any non-numeric and non-dash characters
                                value = value.replace(/[^0-9-]/g, '');
                            
                                // Remove any leading prefix followed by a dash
                                value = value.replace(/^([A-Z]{2,}-)+/, '');
                            
                                // Remove any leading dash
                                value = value.replace(/^[-]+/, '');
                            
                                // Update the input field value
                                e.target.value = value;
                            }}               
                        />
                    </div>
                    {prefixMessage && (
                        <p className="prefix-message">
                            The site that you have ordered from is <br />
                            <strong>{prefixMessage}</strong>
                        </p>
                    )}
                </div>

                <Checkbox
                    isChecked={consentChecked1}
                    label="I agree for my data to be used for research and publication purposes."
                    handleChange={handleCheckboxToggle1}
                />

                {
                    showPopup && (isReCaptchaValid === false) &&
                    <p className="blood-form__recaptcha-error">Error: ReCaptcha is invalid</p>
                }
                <ReCAPTCHA
                    sitekey="6Ld_2pskAAAAAPFePhfrk2K7T3mVksszVs9XF16X"
                    ref={reCaptchaRef}
                />

                <button className="blood-form__submit">Submit</button>

            </form>
        </section>
    );
};

export default BloodForm;
